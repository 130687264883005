main {
  min-height: 80vh;
}

body {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

h3 {
  padding: 1rem 0;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}
.sidebar-icons {
  color: #ff6634;
  font-size: 20px;
  margin-right: 10px;
}
.navbar {
  background-color: #ff6634 !important;
}

.bold {
  font-weight: 800;
}
.btn-warning {
  background-color: #ff6634 !important;
}
